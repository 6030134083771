import React, { useContext } from "react";
import _ from "lodash";
import { sendEventToGA } from "../../lib/functions";
import LoadingIcon from "../loadingIcon";
import Facet from "./Facet";
import { renamedFacetsTitles } from "../../data/renamedFacetTitles";

import {
  SearchDispatchContext,
  SearchStateContext,
} from "../../context/SearchContextProvider";

import { SearchResultsStateContext } from "../../context/SearchResultsContextProvider";
import { DictionaryDataState } from "../../context/DictionaryDataContext";

const hierarchicalFields = {
  s_materialType: "typeOfInstruments",
  s_researchArea: "researchAreas",
  s_participantType: "participantTypes",
  s_proficiencyOfLearners: "proficiencies",
};

export default function Facets(props: any) {
  const searchDispatch = useContext(SearchDispatchContext) || "";
  const searchState = useContext(SearchStateContext) || "";
  const searchResultsState = useContext(SearchResultsStateContext) || "";
  const dictionaryDataState = useContext(DictionaryDataState) || {
    typeOfInstruments: [],
    researchAreas: [],
    participantTypes: [],
    proficiencies: [],
  };

  const { loading } = props;

  // const { typeOfInstruments } = dictionaryDataState;

  const facets: any = [];

  if (
    searchResultsState &&
    searchResultsState.searchResults &&
    searchResultsState.searchResults.aggregations
  ) {
    // eslint-disable-next-line array-callback-return
    renamedFacetsTitles.map((facet) => {
      const name = facet.title;

      if (searchResultsState.searchResults.aggregations[name]) {
        const facetOptions =
          searchResultsState.searchResults.aggregations[name];

        let optionsArray = [];

        const ddName = hierarchicalFields[name];

        if (
          Object.keys(hierarchicalFields).includes(name) &&
          dictionaryDataState[ddName]
        ) {
          /**
           * Prepare this facet for a hierarchical dropdown menu
           */
          optionsArray = dictionaryDataState[ddName]
            .filter((item: any) => item.status !== 'NEW' && item.status !== "REJECTED")
            .map((item: any) => {
            /**
             * Create the indent we need for options
             */
            const indent = "\xa0".repeat(item.depth * 4); // <<< this is the indent (how many &nbsp; )

            // Get the count from bucket
            const count = facetOptions.buckets.filter(
              (bucketItem: any) => bucketItem.key === item.label
            );

            // Create count string
            const docCount =
              count[0] && count[0].doc_count ? `(${count[0].doc_count})` : ``;

            return {
              value: item.label.replace(/\(\d+\)/, "").trim(), // .replace(/ /gi, "-")
              label: `${indent}${item.label} ${docCount}`,
            };
          });
        } else {
          optionsArray = facetOptions.buckets.map((item: any) => {
            const optionLabel = `${item.key} (${item.doc_count})`;
            return {
              value: item.key,
              label: optionLabel,
            };
          });
        }

        // Only show facets that have available options (buckets):
        // if (facetOptions.buckets.length) {
        const options = {
          title: name,
          name,
          isMulti: true,
          isSearchable: true,
          hideSelectedOptions: false,
          // Options must contain `value` and `label`, so we create the array here:
          // options: facetOptions.buckets.map((item: any) => {
          options: optionsArray,
          defaultValue:
            searchState &&
            searchState.searchData &&
            searchState.searchData.filters
              ? searchState.searchData.filters[name]
              : "",
          // Custom handler when we select an option:
          onChange: (e: any) => {
            // Send facet change to GA:
            sendEventToGA({
              event_name: "facetChange",
              facet_name: name,
              values_selected: e,
            });

            // @ts-ignore
            // Save selected values to global state
            searchDispatch({
              type: "facetChangeHandler",
              value: e,
              fieldName: name,
            });
          },
        };

        // @ts-ignore
        facets.push(<Facet key={name} facetOptions={options} />);

        // if (name === "s_materialType") {
        //   facets.push(<Facet key={name} facetOptions={options} />);
        // }
      }
    });
  }

  return (
    <div style={{ width: `100%` }} className="relative">
      {loading ? <LoadingIcon textBeforeIcon="Gathering filters.." /> : facets}
    </div>
  );
}
