/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { isEmpty } from "lodash";
import { navigate } from "gatsby";
import { parseLocation } from "../lib/functions";
import getApiData from "../lib/getApiData";

import Layout from "../components/layout";
import LeftColumnContent from "../components/searchElements/LeftColumnContent";
import RightColumnContent from "../components/searchElements/RightColumnContent";

import {
  SearchStateContext,
  SearchDispatchContext,
} from "../context/SearchContextProvider";

import { SearchResultsDispatchContext } from "../context/SearchResultsContextProvider";

import { DictionaryDataState } from "../context/DictionaryDataContext";
import { findChildren } from "../helpers/searchFilterHelper";

const getSearchResults = async (searchData: any) => {
  return getApiData({
    endpoint: `search`,
    method: "post",
    params: searchData,
  });
};

export default function SearchPage(props: any) {
  const params = parseLocation(props);

  // console.log("🚀 ~ params", params);

  /* ************************** */
  /*           Context          */
  /* ************************** */

  const searchDispatch = useContext(SearchDispatchContext) || "";
  const searchState = useContext(SearchStateContext) || "";
  const searchResultsDispatch = useContext(SearchResultsDispatchContext) || "";
  const dictionaryDataState = useContext(DictionaryDataState) || {
    typeOfInstruments: [],
    researchAreas: [],
    proficiencies: [],
    participantTypes: [],
  };

  /* ************************** */
  /*           State            */
  /* ************************** */

  const [currentPage, setCurrentPageHandler] = useState<any>(
    params && params.page ? params.page : 1
  );
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [sortingOption, setSortingOption] = useState<string>("Relevence");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [didLoadFirstEffect, setDidLoadFirstEffect] = useState<boolean>(false);

  const [ddArrays, setDDArrays] = useState({});
  const [loaded, setLoaded] = useState(false);

  /* ************************** */
  /*           Update DD        */
  /* ************************** */

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      const {
        typeOfInstruments,
        researchAreas,
        proficiencies,
        participantTypes,
      } = dictionaryDataState;

      const ddArray = {
        s_materialType: [],
        s_researchArea: [],
        s_proficiencyOfLearners: [],
        s_participantType: [],
      };

      ddArray.s_materialType = typeOfInstruments;
      ddArray.s_researchArea = researchAreas;
      ddArray.s_proficiencyOfLearners = proficiencies;
      ddArray.s_participantType = participantTypes;

      setDDArrays(ddArray);

      // Set loaded to `true` so that the content is shown to the user
      setLoaded(true);
    }
  }, [dictionaryDataState]);

  /* ************************** */
  /*           Functions        */
  /* ************************** */

  const getfacetState = (params: any) => {
    const obj = {};
    Object.keys(params).forEach((key) => {
      if (key !== "q" && key !== "page") {
        if (params[key]) {
          obj[key] = params[key].split(";").map((item: any) => {
            return {
              value: decodeURIComponent(item),
              label: decodeURIComponent(item),
            };
          });
        }
      }
    });

    return obj;
  };

  /* ************************** */
  /*           Search           */
  /* ************************** */

  interface performSearchProps {
    keyword: string;
    size: number;
    from: number;
    filters: object;
  }

  const performSearch = async (searchData: performSearchProps) => {
    /**
     * Create the new search data - this takes a filter and checks for any children.
     */
    const newSearchData = findChildren(searchData, ddArrays);

    /**
     * Make sure the `keyword` is decoded. Sometime the string it URL encoded like `K%C3%A4llkvist` instead of `Källkvist`.
     */
    newSearchData.keyword = decodeURIComponent(newSearchData.keyword);

    await getSearchResults(newSearchData)
      .then((response) => {
        if (response?.status === 400) {
          setErrorMessage(response.msg);
        } else {
          // Store search results AND search data in Context
          // @ts-ignore
          searchResultsDispatch({
            type: "saveSearchResultsHandler",
            // @ts-ignore
            searchResults: response.data,
          });
        }

        setLoading(false);
        return response;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  /* ************************** */
  /*          Handlers          */
  /* ************************** */

  const resultsPerPageChangeHandler = (num: number) => {
    // @ts-ignore
    searchDispatch({
      type: "resultsPerPageChangeHandler",
      resultsPerPage: num,
    });

    setResultsPerPage(num);
  };

  const sortingOptionsChangeHandler = (fieldName: string) => {
    // @ts-ignore
    searchDispatch({
      type: "sortingOptionChangeHandler",
      sortingOption: fieldName,
    });

    setSortingOption(fieldName);
  };

  /* ************************** */
  /*          useEffects        */
  /* ************************** */

  /**
   * Run only once to update `searchState` with URL parameters, this will also perform the search with these parameters
   */
  useEffect(() => {
    if (loaded) {
      if (process.env.GATSBY_DEBUG_SHOW_CONSOLE) {
        console.log(
          "Fresh page landing, create `searchData` and store in `searchContext` (only once)"
        );
      }

      const searchData = {
        keyword:
          params && params.q && params.q !== "" ? (params.q as string) : "*",
        size: resultsPerPage,
        from: (currentPage - 1) * resultsPerPage,
        filters: getfacetState(params),
      };

      // console.log("🚀 ~ useEffect ~ searchData:", searchData);

      if (params) {
        // @ts-ignore
        searchDispatch({
          type: "searchDataChangeHandler",
          searchData,
        });
      }

      performSearch(searchData);

      setTimeout(() => {
        setDidLoadFirstEffect(true);
      }, 1000);
    }
  }, [loaded]);

  /**
   * Update the navigation when `searchState` has been changed (i.e. keyword or filters)
   */
  useEffect(() => {
    if (didLoadFirstEffect) {
      if (process.env.GATSBY_DEBUG_SHOW_CONSOLE) {
        console.log("`searchState` has been updated, update navigation URL.");
      }

      const searchParams: any = [];

      searchParams.push(
        `q=${
          searchState && searchState.searchData.keyword
            ? searchState.searchData.keyword
            : ""
        }`
      );

      Object.keys(searchState.searchData.filters).forEach((key) => {
        if (searchState.searchData.filters[key].length) {
          const value = searchState.searchData.filters[key]
            .map((item: any) => encodeURIComponent(item.value))
            .join(";");
          searchParams.push(`${key}=${value}`);
        }
      });

      if (props.params && props.params.page) {
        searchParams.push(`page=${props.params.page}`);
      }

      if (searchParams.length > 0) {
        navigate(`/search/?${searchParams.join("&")}`);
      }
    }
  }, [searchState]);

  /**
   * Perform search when `searchState` changed
   */
  useEffect(() => {
    (async () => {
      if (didLoadFirstEffect) {
        if (process.env.GATSBY_DEBUG_SHOW_CONSOLE) {
          console.log("`searchState` has been updated, run a new search.");
        }

        const { searchData } = searchState;
        if (searchData.keyword === "") {
          searchData.keyword = "*";
        }

        setLoading(true);
        await performSearch(searchData);
      }
    })();
  }, [searchState]);

  return (
    <Layout params={params}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <LeftColumnContent />
        </div>

        <div className="col-span-12 md:col-span-8">
          <RightColumnContent
            params={params}
            loading={loading}
            errorMessage={errorMessage}
            resultsPerPage={resultsPerPage}
            sortingOption={sortingOption}
            currentPage={currentPage}
            resultsPerPageChangeHandler={resultsPerPageChangeHandler}
            sortingOptionsChangeHandler={sortingOptionsChangeHandler}
            setCurrentPageHandler={setCurrentPageHandler}
          />
        </div>
      </div>
    </Layout>
  );
}
