/* eslint-disable no-underscore-dangle */
import React from "react";

import Link from "../htmlElements/Link";
import { getAPAInlineRefAll } from "../../helpers/createAPAReference";

interface SearchResultProps {
  result: {
    _source: any;
    title: string;
  };
}

export default function SearchResult(props: SearchResultProps) {
  let title = "";
  let researchArea = "";
  let materialType = "";
  let languageBeingLearned = "";
  let publicationAPAInlineReference: any = "";

  const { result } = props;

  try {
    title = result._source.title[0] || result._source.primaryMaterialType || "";
  } catch (error) {
    console.log("🚀 ~ SearchResult ~ error:", error);
  }

  try {
    publicationAPAInlineReference =
      getAPAInlineRefAll(result._source.s_publicationAPAInlineReference) ||
      "unknown";
  } catch (error) {
    console.log("🚀 ~ SearchResult ~ error ~ publicationReference:", error);
  }

  try {
    researchArea =
      result._source.researchArea
        .sort()
        .filter((item: any) => item !== null)
        .map((item: any) => item.replace("$#$", " > "))
        .join("; ") || "unknown";
  } catch (error) {
    console.log("🚀 ~ SearchResult ~ error ~ researchArea:", error);
  }

  try {
    materialType =
      result._source.materialType
        .sort()
        .filter((item: any) => item !== null)
        .map((item: any) => item.replace("$#$", " > "))
        .join("; ") || "unknown";
  } catch (error) {
    console.log("🚀 ~ SearchResult ~ error ~ materialType:", error);
  }

  try {
    languageBeingLearned =
      result._source.languageBeingLearned
        .sort()
        .filter((item: any) => item !== null)
        .map((item: any) => item.replace("$#$", " > "))
        .join("; ") || "unknown";
  } catch (error) {
    console.log("🚀 ~ SearchResult ~ error ~ languageBeingLearned:", error);
  }

  const detailsLink = `/details/${result._id}`;

  return (
    <div
      style={{ width: `100%` }}
      className="pb-5 mb-5 border-b border-b-gray-2 search-result-item"
    >
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-12">
          <h3>
            <strong>{title}</strong>
          </h3>
        </div>

        <div className="col-span-12">
          <div className="text-sm block">
            <div className="block md:inline md:pr-2">
              <strong>References for publications</strong>:
            </div>
            <div className="block md:inline">
              {publicationAPAInlineReference}
            </div>
          </div>

          <div className="text-sm block">
            <div className="block md:inline md:pr-2">
              <strong>General Research Area(s)</strong>:
            </div>
            <div className="block md:inline">{researchArea}</div>
          </div>

          <div className="text-sm block">
            <div className="block md:inline md:pr-2">
              <strong>Type of material</strong>:
            </div>
            <div className="block md:inline">{materialType}</div>
          </div>

          <div className="text-sm block">
            <div className="block md:inline md:pr-2">
              <strong>Language being used/learned</strong>:
            </div>
            <div className="block md:inline">{languageBeingLearned}</div>
          </div>

          <div className="text-sm block pt-3">
            <Link to={detailsLink} text="View more details/download" />
          </div>
        </div>
      </div>
    </div>
  );
}
