import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import _, { sortBy } from "lodash";
import Pagination from "../contentElements/pagination";

import H1 from "../htmlElements/h1";
import LoadingIcon from "../loadingIcon";
import SearchResult from "./SearchResult";
import ClearUpTitle from "../../helpers/ClearUpTitle";

import {
  SearchDispatchContext,
  SearchStateContext,
} from "../../context/SearchContextProvider";
import { SearchResultsStateContext } from "../../context/SearchResultsContextProvider";
import CurrentFilters from "./CurrentFilters";

export default function RightColumnContent(props: any) {
  const [searchResultsDisplay, setSearchResultsDisplay] = useState<string>("");

  const searchState = useContext(SearchStateContext) || "";
  const searchDispatch = useContext(SearchDispatchContext) || "";
  const searchResultsState = useContext(SearchResultsStateContext) || "";

  const [headerText, setHeaderText] = useState("Search results for everything");

  const resultsPerPageOptions = () => {
    const options = [2, 5, 10, 20, 50, 100].map((num) => {
      return (
        <option value={num} key={uuidv4()}>
          {num}
        </option>
      );
    });

    return (
      <select
        value={props.resultsPerPage}
        onChange={(e) =>
          props.resultsPerPageChangeHandler(parseInt(e.target.value))
        }
      >
        {options}
      </select>
    );
  };

  const sortingOptions = () => {
    const options = [
      "Relevance",
      "Title",
      "Author",
      "Publication date",
      "Instrument type",
    ].map((fieldName) => {
      return (
        <option value={fieldName} key={uuidv4()}>
          {fieldName}
        </option>
      );
    });

    return (
      <select
        value={props.sortingOption}
        onChange={(e) => props.sortingOptionsChangeHandler(e.target.value)}
      >
        {options}
      </select>
    );
  };

  useEffect(() => {
    const _searchResults =
      (searchResultsState.searchResults &&
        searchResultsState.searchResults.hits.hits) ||
      [];

    const _searchResultsSorted =
      props.sortingOption === "Title"
        ? sortBy(_searchResults, ["_source.title"])
        : props.sortingOption === "Instrument type"
        ? sortBy(_searchResults, ["_source.materialType"])
        : _searchResults;

    const searchResultsDisplay = _searchResultsSorted
      ? _searchResultsSorted.map((result: object) => {
          return <SearchResult key={uuidv4()} result={result} />;
        })
      : "";

    setSearchResultsDisplay(searchResultsDisplay);
    // }, [props.searchResults, props.sortingOption]);
  }, [searchResultsState, props.sortingOption]);

  const numberOfResults =
    (searchResultsState.searchResults &&
      searchResultsState.searchResults.hits.total.value) ||
    0;

  const totalPages = Math.ceil(numberOfResults / props.resultsPerPage);

  const resultsStart =
    numberOfResults === 0
      ? 0
      : props.resultsPerPage *
          (props.currentPage < 1
            ? 1
            : props.currentPage > totalPages
            ? totalPages
            : props.currentPage) -
        props.resultsPerPage +
        1;
  const resultsEnd =
    resultsStart + props.resultsPerPage - 1 > numberOfResults
      ? numberOfResults
      : resultsStart + props.resultsPerPage - 1;

  const selectedFacets: any = [];

  if (searchState && searchState.searchData && searchState.searchData.filters) {
    Object.keys(searchState.searchData.filters).forEach((name) => {
      if (name !== "containsFiles") {
        const values = searchState.searchData.filters[name];
        values.map((value: any) => {
          selectedFacets.push(value);
        });
      }
    });
  }

  const noResultsMessage = (
    <div className="text-sm mb-5">
      We could not found any results.
      {selectedFacets.length ? (
        <span>
          <br />
          <br />
          Try removing some selected filters to find more results.
          <br />
        </span>
      ) : (
        ""
      )}
      {/* <br /> */}
      {/* {selectedFacets} */}
    </div>
  );

  useEffect(() => {
    if (searchState) {
      if (
        searchState?.searchData?.keyword !== "*" &&
        searchState?.searchData?.keyword !== ""
      ) {
        let searchKeyword = decodeURI(searchState?.searchData?.keyword ?? "*");

        // Remove quotes at the start and end if they exist
        if (searchKeyword.startsWith('"') && searchKeyword.endsWith('"')) {
          searchKeyword = searchKeyword.slice(1, -1);
        }

        setHeaderText(`Search results for "${searchKeyword}"`);
      }
    }
  }, [searchState]);

  return (
    <>
      <H1 innerContent={headerText} additionalClasses="pb-5" />

      {props.loading ? (
        <LoadingIcon textBeforeIcon="Loading results.." />
      ) : props.errorMessage ? (
        props.errorMessage
      ) : (
        <>
          <p className="text-sm mb-5">
            These search results may contain some records which hold information
            about materials but IRIS does not (yet) hold the materials
            themselves. If you need these materials, we recommend that you
            contact the author(s) of the research.
          </p>

          <div className="inline-block w-full border-b border-b-gray-2 mb-0 pb-0">
            <div className="float-left text-sm mb-5 ">
              Results {resultsStart} - {resultsEnd} of {numberOfResults}
            </div>
            <div className="float-right text-sm ml-5">
              Sort by {sortingOptions()}
            </div>
            <div className="float-right text-sm ml-5">
              Results per page {resultsPerPageOptions()}
            </div>
          </div>

          <CurrentFilters />

          {searchResultsDisplay.length
            ? searchResultsDisplay
            : noResultsMessage}

          {numberOfResults > 0 ? (
            <Pagination
              params={props.params}
              query={
                searchState && searchState.searchData
                  ? searchState.searchData.keyword
                  : ""
              }
              currentPage={props.currentPage}
              totalPages={totalPages}
              setCurrentPageHandler={props.setCurrentPageHandler}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
