import React, { useContext, useEffect, useState } from "react";
import { sendEventToGA } from "../../lib/functions";

import {
  SearchDispatchContext,
  SearchStateContext,
} from "../../context/SearchContextProvider";

export default function SearchBox() {
  const searchDispatch = useContext(SearchDispatchContext) || "";
  const searchState = useContext(SearchStateContext) || "";

  const [value, setValue] = useState<string>("");

  let searchTimeout: any;

  useEffect(() => {
    setValue(searchState.searchData.keyword);
  }, [searchState]);

  const performSearch = (e: any) => {
    if (value !== e.target.value) {
      // @ts-ignore
      searchDispatch({
        type: "searchChangeHandler",
        value: e.target.value,
      });
    }
  };

  return (
    <div className="pb-3 mb-3 border-b border-b-gray-2 rounded-r-none">
      <div className="font-bold pb-3">Search query:</div>

      <div className="text-sm block">
        <div className="input-group relative flex flex-wrap items-stretch w-full">
          <input
            type="text"
            name="q"
            key="searchKey"
            placeholder="Search for..."
            className=" css-1s2u09g-control px-2 rounded-r-none form-control relative flex-auto"
            onChange={(e) => {
              setValue(e.target.value);

              // Send facet change to GA:
              sendEventToGA({
                event_name: "queryChange",
                facet_name: "q",
                values_selected: e.target.value,
              });

              if (searchTimeout) {
                clearTimeout(searchTimeout);
              }

              searchTimeout = setTimeout(() => {
                performSearch(e);
              }, 500);
            }}
            value={decodeURI(value)}
          />

          <button
            className="btn inline-block px-6 py-2.5 bg-blue text-white font-medium text-xs leading-tight uppercase shadow-md 
              rounded rounded-l-none
              hover:bg-blue-700 hover:shadow-lg 
              focus:bg-blue-700 focus:outline-none focus:ring-0 
              active:bg-blue-800 active:shadow-lg 
              transition duration-150 
              ease-in-out flex items-center"
            type="button"
            id="button-addon2"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="search"
              className="w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
